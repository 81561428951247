import React from "react";
import "./style.css";
import LinkButton from "../../components/link-button/index.jsx";

import Github from "../svg/github";
import Download from "../svg/download";
import Linkedin from "../svg/linkedin";
import Mail from "../svg/mail";
const Intro = () => {
  return (
    <div className="FullPage">
      {/* <div style={{backgroundColor:'red'}}> */}
      <div className="GradientBackground">
        <h1>Gatien Chenu</h1>

        <h2 className="Text">
          Computer Science Student <br />
          Specialised in physics and machine learning
        </h2>
      </div>
      {/* </div> */}
      <ul className="ButtonList">
        <LinkButton
          link="https://github.com/gatienc"
          text="Github"
          SvgComponent={Github}
        />
        <LinkButton
          link="/resume.pdf"
          text="Download CV"
          SvgComponent={Download}
        />
        <LinkButton
          link="https://linkedin.com/in/gatien-chenu/"
          text="LinkedIn"
          SvgComponent={Linkedin}
        />
        <LinkButton
          link="mailto:gatien@chenu.me"
          text="Contact Me"
          SvgComponent={Mail}
        />
      </ul>
    </div>
  );
};
export default Intro;
