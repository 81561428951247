import React from "react";
import "./style.css";
import TechSvg from "../svg/TechSvg";
import Button from "../button";


const TechStack = () => {
    //get all the svg files in the folder
    const components = Object.values(TechSvg);


    return (
      <div className="techstack-container">
        <a className="techstack-main-container" href="#techstack">
          <div className="techstack-title-container">
            <Button text="Details" />
          </div>
          <div className="techstack-icon-container">
            <div className="slide-track">
              {components.map((Component, index) => (
                <Component key={index} />
              ))}
              {components.map((Component, index) => (
                <Component key={index} />
              ))}
            </div>
          </div>
        </a>
      </div>
    );
    }
export default TechStack;