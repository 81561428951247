import React from "react";
import "./style.css";

const About = () => {
    return (
        <div className="AboutText">
            👋 Hi, I am Gatien, an engineer with a passion about physics and data science.<br/>
            👨‍🔬 I followed an intensive advanced maths and physics education for the competitive entrance to french engineering schools from 2019 to 2021<br/>
            🎒 I am a data processing majors at Télécom Physique Strasbourg french engineering school<br/>
            🚀 I love building useful and meaningful projects<br/>
        </div>

    );
    }
export default About;