import React from "react";
import "./style.css";

const Button = ({onClick,text}) => {

    return (
        <div className="button-main-container" onClick={onClick}>
            <div className="button-text">
            {text}
            </div>
        </div>
    );
    }
export default Button;