import React from "react";
import "./style.css";
const work_experience_data = [
  {
    id: "work_experience_0",
    company_logo_path: "/assets/Logo_Telecom_SudParis.png",
    company: "Telecom Sud Paris",
    location: "Paris-Saclay, France",
    date: "Jun - Aug 2023",
    description:
      "Research Intern on Bluetooth Low Energy indoor localization algorithm development",
    bullet_points: [
      "Built a Python package as a toolbox to process and visualize bluetooth measuring instrument output.",
      "Developed custom signal processing ﬁlters and impactful visualization techniques using Pandas, Folium, and Plotly.",
      "Utilized Shapely and GeoPandas to create an innovative algorithm, minimizing false positive occurrences in localization.",
    ],
  },
  {
    id: "work_experience_1",
    company_logo_path: "/assets/shadow.png",
    company: "Shadow",
    location: "Strasbourg, France",
    date: "Sep 2022- May 2023",
    description: "Project Leader on Benchmarking Automation",
    bullet_points: [
      "Automated the installation, conﬁguration and execution of Phoronix Test Suite using Python (Subprocess).",
      "Built a desktop application using Tkinter to manage the automation of the benchmarking process.",
      "Managed a team of 5 engineering students for 8 months in parallel with courses.",
    ],
  },
  {
    id: "work_experience_2",
    company_logo_path: "/assets/icube.png",
    company: "iCube",
    location: "Strasbourg, France",
    date: "Jun 2022- Jul 2023",
    description: "Research Intern on Bacteria Classiﬁcation",
    bullet_points: [
      "Fine-tuned a Pytorch Convolutional Neural Network (Resnet-18 model) to differentiate 5 bacteria species, reached 93 % accuracy.",
      "Performed data augmentation using Albumentations resulting in a 15 % improvement in model efﬁciency.",
    ],
  },
  {
    id: "work_experience_3",
    company_logo_path: "/assets/sers.png",
    company: "SERS",
    location: "Strasbourg, France",
    date: "May 2022- Jun 2023",
    description: "3D Maker",
    bullet_points: [
      'Realised 3D modelization of the "Manufacture des Tabacs" in SolidWorks',
      "3D printed 100 miniatures of the model",
    ],
  },
];
const education_data = [
  {
    id: "education_0",
    company_logo_path: "/assets/Telecom_Physique_Strasbourg.png",
    company: "Télécom Physique Strasbourg",
    location: "Strasbourg, France",
    date: "2021 - 2023",
    description: "Dual degree : Engineering School and Master Science",
    bullet_points: [
      "Signal Processing, Fundamental Physics, Computer Vision, Machine Learning, Data Science, Big Data, Cybersecurity, Robotics, Control Theory, Optimization, Statistics, Probability, Fundamental Physics and Electronics",
      'Actively contributed to the "info-telecom-strasbourg" computer science association, significantly expanding my knowledge in computer science through collaborative projects, workshops, and events.',
    ],
  },
  {
    id: "education_1",
    company_logo_path: "/assets/jeanbart.png",
    company: "Jean Bart",
    location: "Dunkerque, France",
    date: "2019 - 2021",
    description: "Maths and physics preparatory classes",
    bullet_points: [
      "Intensive advanced maths and physics for the competitive entrance to French engineering schools.",
      'Completed a rigorous and intensive two-year program in the prestigious French "Classe Préparatoire aux Grandes Écoles" (CPGE).',
      "Acquired a strong foundation in various subjects (especially math and physics), fostering a multidisciplinary approach to problem-solving and decision-making.",
      "Demonstrated ability to manage high-pressure environments, tight deadlines, and heavy workloads.",
    ],
  },
];

const ExperienceComponent = ({ data }) => {
  console.log(data);

  const listItems =
    typeof data.bullet_points !== "undefined"
      ? data.bullet_points.map((bullet_point, index) => (
          <li key={index}>{bullet_point}</li>
        ))
      : null;
  return (
    <div className="ExperienceContainer">
      <div className="Top">
        <h3>
          <img className="Logo" src={data.company_logo_path} alt="" />
          {data.company}
        </h3>
        <div className="Location-Date">
          {data.location}, {data.date}
        </div>
      </div>
      <div className="row" />

      <div className="ExperienceDataContainer">
        <div className="Description">{data.description}</div>

        <ul className="BulletPoints">{listItems}</ul>
      </div>
    </div>
  );
};

export const WorkExperience = () => {
  const ListWorkExperience = work_experience_data.map((data, index) => (
    <ExperienceComponent key={index} data={data} />
  ));
  return (
    <div className="MainWorkExperienceContainer">{ListWorkExperience}</div>
  );
};
export const Education = () => {
  const ListEducation = education_data.map((data, index) => (
    <ExperienceComponent key={index} data={data} />
  ));
  return <div className="MainWorkExperienceContainer">{ListEducation}</div>;
};
